import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/manager',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/manager/home',  // 重定向到主页
    children: [
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', name: 'Home', meta: { name: '系统首页' }, component: () => import('../views/manager/Home') },
      { path: 'admin', name: 'Admin', meta: { name: '管理员信息' }, component: () => import('../views/manager/Admin') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: '个人信息' }, component: () => import('../views/manager/AdminPerson') },
      { path: 'password', name: 'Password', meta: { name: '修改密码' }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: '公告信息' }, component: () => import('../views/manager/Notice') },
      { path: 'codes', name: 'Codes', meta: { name: '认证码' }, component: () => import('../views/manager/Codes') },
      { path: 'blackList', name: 'BlackList', meta: { name: '黑名单' }, component: () => import('../views/manager/BlackList') },
      { path: 'resources', name: 'Resources', meta: { name: '资源列表' }, component: () => import('../views/manager/Resources') },
      { path: 'records', name: 'Records', meta: { name: '资源获取记录' }, component: () => import('../views/manager/Records') },
      { path: 'codeResources', name: 'CodeRecords', meta: { name: '源码列表' }, component: () => import('../views/manager/CodeResources') },
      { path: 'codeRecords', name: 'CodeRecords', meta: { name: '源码获取记录' }, component: () => import('../views/manager/CodeRecords') },
      { path: 'downloadRecords', name: 'DownloadRecords', meta: { name: '源码下载记录' }, component: () => import('../views/manager/DownloadRecords') },
      { path: 'logs', name: 'Logs', meta: { name: '操作日志' }, component: () => import('../views/manager/Logs') },
    ]
  },
  { path: '/', name: 'FHome', meta: { name: '系统首页' }, component: () => import('../views/front/Home') },
  { path: '/codes', name: 'FCodes', meta: { name: '系统首页' }, component: () => import('../views/front/Codes') },
  { path: '/sourceCode', name: 'SourceCode', meta: { name: '系统首页' }, component: () => import('../views/front/SourceCode') },
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/apply', name: 'Apply', meta: { name: '申请认证码' }, component: () => import('../views/front/Apply.vue') },
  { path: '/auth', name: 'Auth', meta: { name: '知识星球认证回调' }, component: () => import('../views/front/Auth.vue') },
  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
// router.beforeEach((to ,from, next) => {
//   let user = JSON.parse(localStorage.getItem("xm-doc-user") || '{}');
//   if (to.path === '/') {
//     if (user.role) {
//       if (user.role === 'USER') {
//         next('/front/home')
//       } else {
//         next('/home')
//       }
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })

export default router
